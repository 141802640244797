<template>
  <resource-page :resource="resource" :title="title">
    <template #default="{ grid }">
      <resource-table
        v-if="grid != null"
        ref="resourceTable"
        :resource="resource"
        :grid="grid"
        :initial-filters="{ status: ['requested', 'approved'] }"
        :initial-sort="{ field: 'return_received_days', order: 'desc' }"
        :row-class="(item) => ({ 'is-cancellation': item.is_cancellation })"
      >
        <template #column-order-reference="{ row }">
          <a :href="row.storm_url" target="_blank">{{ row.order.reference }}</a>
        </template>
        <template #column-return_received="{ row }">
          <span :title="formatDate(row.return_received, true)">
            {{ formatDate(row.return_received) }}
          </span>
        </template>
        <template #column-order-created_at="{ row }">
          <span :title="formatDate(row.order.created_at, true)">
            {{ formatDate(row.order.created_at) }}
          </span>
        </template>
        <template #column-created_at="{ row }">
          <span :title="formatDate(row.created_at, true)">
            {{ formatDate(row.created_at) }}
          </span>
        </template>
        <template #column-approved_at="{ row }">
          <span :title="formatDate(row.approved_at, true)">
            {{ formatDate(row.approved_at) }}
          </span>
        </template>
        <template #column-sent_at="{ row }">
          <span :title="formatDate(row.sent_at, true)">
            {{ formatDate(row.sent_at) }}
          </span>
        </template>
        <template #column-amount_eur="{ row }">
          <div :title="row.amount_original" class="text-end">
            <strong>{{ $filters.currencyEUR(row.amount_eur) }}</strong>
          </div>
        </template>
        <template #column-payment="{ row }">
          <div class="text-center">
            <a
              v-if="
                row.initial_payment != null &&
                row.initial_payment.provider !== 'sofort'
              "
              :href="row.initial_payment.url"
              target="_blank"
            >
              <span
                v-if="row.initial_payment.provider !== 'adyen'"
                class="fab text-black payment-provider-logo"
                :class="`fa-cc-${row.initial_payment.provider}`"
                :title="row.initial_payment.reference"
              />
              <img
                v-if="row.initial_payment.provider === 'adyen'"
                src="/media/icons/adyen.png"
                width="50"
                alt="Adyen"
              />
            </a>
            <template v-if="!row.is_processing">
              <span
                v-if="
                  row.payment !== null &&
                  row.payment.provider === 'sofort' &&
                  row.payment.reference
                "
              >
                Bank Transfer<br />
                #{{ row.payment.reference }}
              </span>
              <div
                v-if="
                  (row.payment === null && row.is_refunded) ||
                  (row.payment !== null && row.payment.reference === null)
                "
              >
                Bank Transfer
                <button
                  class="btn btn-sm btn-secondary"
                  @click="() => showModal(row)"
                >
                  Set Reference
                </button>
              </div>
            </template>
          </div>
        </template>
        <template #column-return_received_days="{ row }">
          <div :title="row.return_received_days" class="text-center">
            <span
              class="fw-bold px-2 rounded text-white"
              :class="{
                'bg-success': row.return_received_days < 11,
                'bg-warning':
                  row.return_received_days > 10 &&
                  row.return_received_days < 14,
                'bg-danger': row.return_received_days > 13,
              }"
            >
              {{ row.return_received_days }}
            </span>
          </div>
        </template>
        <template #column-deleted_at="{ row }">
          <span :title="formatDate(row.deleted_at, true)">
            {{ formatDate(row.deleted_at) }}
          </span>
        </template>
        <template #actions="{ row }">
          <payment-refund-table-actions
            :row="row"
            @refresh-resource="updateTable"
          />
        </template>
      </resource-table>
    </template>
  </resource-page>
  <payment-reference-modal
    v-if="state.referenceUpdate != null"
    :row="state.referenceUpdate"
    @hide="hideModal"
    @success="updateTable"
  />
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import PaymentRefundTableActions from "@/modules/payments/widgets/PaymentRefundTableActions.vue";
import { DateTime } from "luxon";
import ResourcePage from "@/modules/resources/views/ResourcePage.vue";
import ResourceTable from "@/modules/resources/components/ResourceTable/ResourceTable.vue";
import PaymentReferenceModal from "@/modules/payments/views/PaymentReferenceModal.vue";
import { setPageTitle } from "@/core/helpers/dom";

interface ComponentData {
  referenceUpdate: null | Record<string, unknown>;
}

export default defineComponent({
  name: "PaymentRefundResourcePage",
  components: {
    PaymentReferenceModal,
    ResourceTable,
    ResourcePage,
    PaymentRefundTableActions,
  },
  emits: ["table-updated"],
  setup(props, { emit }) {
    const resource = "payment-refund";
    const title = "Refunds";

    const resourceTable = ref<typeof ResourceTable | null>(null);

    const updateTable = () => {
      resourceTable.value?.loadData();

      emit("table-updated");
    };

    const state = reactive<ComponentData>({
      referenceUpdate: null,
    });

    const showModal = (row: Record<string, unknown>) => {
      state.referenceUpdate = row;
    };

    const hideModal = () => {
      state.referenceUpdate = null;
    };

    setPageTitle("Refunds");

    return {
      resource,
      title,
      resourceTable,
      updateTable,
      state,
      showModal,
      hideModal,
    };
  },
  methods: {
    formatDate(date: string, time = false): string {
      if (date == null) {
        return "-";
      }
      return DateTime.fromISO(date).toFormat(time ? "hh:mm:ss" : "dd LLL yyyy");
    },
  },
});
</script>

<style scoped>
.payment-provider-logo {
  font-size: 30px;
}
</style>
<style type="text/css" lang="scss">
tr.is-cancellation {
  background-color: #ffdfdf;
}
</style>
