<template>
  <div v-if="state.loading" class="text-center">
    <loading-indicator />
  </div>
  <div v-if="!state.loading && row.is_processing" class="text-center">
    <button
      class="ms-2 btn btn-light btn-sm"
      @click="() => $emit('refresh-resource')"
    >
      Awaiting result
      <loading-indicator size="sm" />
    </button>
  </div>
  <div v-if="!state.loading && !row.is_processing" class="text-center">
    <template v-if="!row.is_deleted">
      <button
        v-if="!row.is_approved"
        class="btn btn-success btn-icon btn-sm"
        title="Accept"
        :disabled="userCanAct"
        @click="approve"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        v-if="!row.is_refunded"
        class="ms-2 btn btn-danger btn-icon btn-sm"
        title="Decline"
        :disabled="userCanAct"
        @click="cancel"
      >
        <i class="fas fa-minus"></i>
      </button>
      <button
        v-if="row.is_approved && !row.is_refunded"
        class="ms-2 btn btn-secondary btn-sm"
        :disabled="userCanAct"
        @click="refund"
      >
        Refund now
      </button>
    </template>
    <span v-if="row.is_refunded" class="text-success">
      Refunded <i class="fas fa-check text-success"></i>
    </span>
    <span v-if="row.is_deleted" class="text-danger">
      Cancelled <i class="fas fa-minus text-danger"></i>
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive } from "vue";
import { useStore } from "vuex";
import { MessageBoxData } from "element-plus/es/components/message-box/src/message-box.type";
import { ElMessageBox } from "element-plus";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";

interface PaymentRefund {
  id: number;
  is_approved: boolean;
  is_refunded: boolean;
  is_deleted: boolean;
  is_processing: boolean;
}

export default defineComponent({
  name: "PaymentRefundTableActions",
  components: { LoadingIndicator },
  props: {
    row: {
      type: Object as PropType<PaymentRefund>,
      required: true,
    },
  },
  emits: ["refresh-resource"],
  setup(props, { emit }) {
    const state = reactive({
      loading: false,
    });

    const userCanAct = computed(() => {
      const store = useStore();
      const user = store.getters.currentUser;

      return !user.roles.includes("REFUND_APPROVER");
    });

    const confirmAction = (): Promise<MessageBoxData> => {
      return ElMessageBox.confirm("Are you sure?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "warning",
        center: true,
      });
    };

    const approve = () => {
      confirmAction().then(() => {
        state.loading = true;
        ApiService.post(`payments/refund/${props.row.id}/approve`)
          .then(() => {
            emit("refresh-resource");
          })
          .catch(Notify.apiError)
          .finally(() => {
            state.loading = false;
          });
      });
    };

    const refund = () => {
      confirmAction().then(() => {
        state.loading = true;
        ApiService.post(`payments/refund/${props.row.id}/refund-now`)
          .then(() => {
            emit("refresh-resource");
          })
          .catch(Notify.apiError)
          .finally(() => {
            state.loading = false;
          });
      });
    };

    const cancel = () => {
      confirmAction().then(() => {
        state.loading = true;
        ApiService.post(`payments/refund/${props.row.id}/cancel`)
          .then(() => {
            emit("refresh-resource");
          })
          .catch(Notify.apiError)
          .finally(() => {
            state.loading = false;
          });
      });
    };

    return {
      userCanAct,
      approve,
      state,
      refund,
      cancel,
    };
  },
});
</script>

<style scoped></style>
