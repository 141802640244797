
import { computed, defineComponent, PropType, reactive } from "vue";
import { useStore } from "vuex";
import { MessageBoxData } from "element-plus/es/components/message-box/src/message-box.type";
import { ElMessageBox } from "element-plus";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";

interface PaymentRefund {
  id: number;
  is_approved: boolean;
  is_refunded: boolean;
  is_deleted: boolean;
  is_processing: boolean;
}

export default defineComponent({
  name: "PaymentRefundTableActions",
  components: { LoadingIndicator },
  props: {
    row: {
      type: Object as PropType<PaymentRefund>,
      required: true,
    },
  },
  emits: ["refresh-resource"],
  setup(props, { emit }) {
    const state = reactive({
      loading: false,
    });

    const userCanAct = computed(() => {
      const store = useStore();
      const user = store.getters.currentUser;

      return !user.roles.includes("REFUND_APPROVER");
    });

    const confirmAction = (): Promise<MessageBoxData> => {
      return ElMessageBox.confirm("Are you sure?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "warning",
        center: true,
      });
    };

    const approve = () => {
      confirmAction().then(() => {
        state.loading = true;
        ApiService.post(`payments/refund/${props.row.id}/approve`)
          .then(() => {
            emit("refresh-resource");
          })
          .catch(Notify.apiError)
          .finally(() => {
            state.loading = false;
          });
      });
    };

    const refund = () => {
      confirmAction().then(() => {
        state.loading = true;
        ApiService.post(`payments/refund/${props.row.id}/refund-now`)
          .then(() => {
            emit("refresh-resource");
          })
          .catch(Notify.apiError)
          .finally(() => {
            state.loading = false;
          });
      });
    };

    const cancel = () => {
      confirmAction().then(() => {
        state.loading = true;
        ApiService.post(`payments/refund/${props.row.id}/cancel`)
          .then(() => {
            emit("refresh-resource");
          })
          .catch(Notify.apiError)
          .finally(() => {
            state.loading = false;
          });
      });
    };

    return {
      userCanAct,
      approve,
      state,
      refund,
      cancel,
    };
  },
});
