<template>
  <modal-component id="set-payment-ref-modal" ref="modal" @hide="$emit('hide')">
    <template #title>Refund #{{ row.id }}. Set payment reference</template>
    <template #default>
      <form
        class="form w-100"
        :validation-schema="validationSchema"
        @submit="onSubmit"
      >
        <div>
          <Field
            class="form-control form-control-sm w-full"
            type="text"
            placeholder="Enter payment reference"
            name="reference"
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="reference" />
            </div>
          </div>
        </div>
        <form-submit-button :loading="state.loading">Save</form-submit-button>
      </form>
    </template>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import * as Yup from "yup";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";

export default defineComponent({
  name: "PaymentReferenceModal",
  components: { FormSubmitButton, ModalComponent, Field, ErrorMessage },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  emits: ["hide", "success"],
  setup(props, { emit }) {
    const validationSchema = Yup.object({
      reference: Yup.string().required().label("Payment Reference"),
    });

    const { handleSubmit } = useForm({
      validationSchema,
    });

    const onSubmit = handleSubmit((values) => {
      ApiService.post(`payments/refund/${props.row.id}/set-reference`, values)
        .then(() => {
          emit("success");
          modal.value?.hide();
        })
        .catch(Notify.apiError)
        .finally(() => {
          state.loading = false;
        });
    });

    const state = reactive({
      loading: false,
    });

    const modal = ref<typeof ModalComponent | null>(null);

    return {
      validationSchema,
      onSubmit,
      state,
      modal,
    };
  },
});
</script>

<style scoped></style>
