
import { defineComponent, onMounted, PropType, ref } from "vue";

export default defineComponent({
  name: "ModalComponent",
  props: {
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<"small" | "medium" | "large" | "x-large">,
      required: false,
      default: "x-large",
    },
    closeOnBackgroundClick: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["hide"],
  setup(props, { expose, emit, slots }) {
    const opener = ref(null);
    const closer = ref(null);

    onMounted(() => {
      (opener.value as unknown as HTMLButtonElement).click();
    });

    const hasSlot = (name = "default"): boolean => {
      return !!slots[name];
    };

    const hide = (isBackgroundCLick = false) => {
      if (isBackgroundCLick && !props.closeOnBackgroundClick) {
        return;
      }
      (closer.value as unknown as HTMLButtonElement).click();
      setTimeout(() => emit("hide"), 300);
    };

    expose({
      hide,
    });

    return {
      hasSlot,
      hide,
      opener,
      closer,
    };
  },
});
