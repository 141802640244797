
import { defineComponent, reactive, ref } from "vue";
import PaymentRefundTableActions from "@/modules/payments/widgets/PaymentRefundTableActions.vue";
import { DateTime } from "luxon";
import ResourcePage from "@/modules/resources/views/ResourcePage.vue";
import ResourceTable from "@/modules/resources/components/ResourceTable/ResourceTable.vue";
import PaymentReferenceModal from "@/modules/payments/views/PaymentReferenceModal.vue";
import { setPageTitle } from "@/core/helpers/dom";

interface ComponentData {
  referenceUpdate: null | Record<string, unknown>;
}

export default defineComponent({
  name: "PaymentRefundResourcePage",
  components: {
    PaymentReferenceModal,
    ResourceTable,
    ResourcePage,
    PaymentRefundTableActions,
  },
  emits: ["table-updated"],
  setup(props, { emit }) {
    const resource = "payment-refund";
    const title = "Refunds";

    const resourceTable = ref<typeof ResourceTable | null>(null);

    const updateTable = () => {
      resourceTable.value?.loadData();

      emit("table-updated");
    };

    const state = reactive<ComponentData>({
      referenceUpdate: null,
    });

    const showModal = (row: Record<string, unknown>) => {
      state.referenceUpdate = row;
    };

    const hideModal = () => {
      state.referenceUpdate = null;
    };

    setPageTitle("Refunds");

    return {
      resource,
      title,
      resourceTable,
      updateTable,
      state,
      showModal,
      hideModal,
    };
  },
  methods: {
    formatDate(date: string, time = false): string {
      if (date == null) {
        return "-";
      }
      return DateTime.fromISO(date).toFormat(time ? "hh:mm:ss" : "dd LLL yyyy");
    },
  },
});
