<template>
  <div ref="opener" data-bs-toggle="modal" :data-bs-target="'#' + id" />
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    @click.self="() => hide(true)"
  >
    <div
      class="modal-dialog"
      :class="{
        'modal-sm': size === 'small',
        'modal-md': size === 'medium',
        'modal-lg': size === 'large',
        'modal-xl': size == null || size === 'x-large',
      }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <div ref="closer" data-bs-dismiss="modal" />

          <div
            v-if="hasSlot('complexHeader')"
            class="modal-complex-header w-100"
          >
            <slot name="complexHeader" />
          </div>

          <h5 v-if="hasSlot('title')" class="modal-title">
            <slot name="title" />
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            aria-label="Close"
            @click="() => hide()"
          >
            <span class="bi bi-x-lg"></span>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <slot />
        </div>

        <div v-if="hasSlot('footer')" class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from "vue";

export default defineComponent({
  name: "ModalComponent",
  props: {
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<"small" | "medium" | "large" | "x-large">,
      required: false,
      default: "x-large",
    },
    closeOnBackgroundClick: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["hide"],
  setup(props, { expose, emit, slots }) {
    const opener = ref(null);
    const closer = ref(null);

    onMounted(() => {
      (opener.value as unknown as HTMLButtonElement).click();
    });

    const hasSlot = (name = "default"): boolean => {
      return !!slots[name];
    };

    const hide = (isBackgroundCLick = false) => {
      if (isBackgroundCLick && !props.closeOnBackgroundClick) {
        return;
      }
      (closer.value as unknown as HTMLButtonElement).click();
      setTimeout(() => emit("hide"), 300);
    };

    expose({
      hide,
    });

    return {
      hasSlot,
      hide,
      opener,
      closer,
    };
  },
});
</script>

<style scoped>
.modal-dialog {
  z-index: 1065;
}
</style>
